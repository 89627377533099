import { currentTimestamp } from "@/config/store/StoreItemsModule/helpers"

export default () => ({
  ADD_ITEM: (state, item) => {
    item._addedAt = `t_${currentTimestamp()}`
    state.items.unshift(item)
  },

  SET_INVALID_ITEM: (state, index) => {
    const item = state.items[index]
    state.items.splice(index, 1, { ...item, invalid: true })
  }
})
