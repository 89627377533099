import { api } from "@/config"
import { handleFailedResponse } from "@/helpers/common"

const mapFilters = filters => ({ search_value: filters.searchValue })

const buildParams = ({ filters, pagination, sorting }, inventory_group) => ({
  filters: mapFilters(filters),
  pagination,
  sorting,
  inventory_group
})

const handleResponse = (commit, response) => {
  const { items, meta } = response.data.data

  commit("SET_ITEMS", items)
  commit("SET_PAGINATION_DATA", meta.pagination)
}

export default ({ baseURI }) => ({
  CREATE_ITEM: async ({ commit, state }, inventory_group) => {
    try {
      const params = buildParams(state, inventory_group)

      const response = await api.post(baseURI, params)
      handleResponse(commit, response)
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  },

  UPDATE_ITEM: async ({ commit, state }, { id, ...inventory_group }) => {
    try {
      const params = buildParams(state, inventory_group)

      const response = await api.put(`${baseURI}/${id}`, params)
      handleResponse(commit, response)
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  }
})
